<template>
  <InstructionViewPublisher
    v-if="isEditorOrPublisher"
    ref="instructionViewPublisher"
    :task="task"
  />
  <InstructionViewInspector
    v-else
    ref="instructionViewInspector"
    :task="task"
  />
</template>

<script>
import InstructionViewPublisher from './InstructionViewPublisher';
import InstructionViewInspector from './InstructionViewInspector';
import {
  WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION,
  WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION,
} from '../../../store/websocket/actions/actionTypes';
import {
  ADD_QUESTION,
  MUTATION_UPDATE_QUESTION,
  MUTATION_UPDATE_QUESTIONS,
  REMOVE_QUESTION,
} from '../../../store/questions/mutations/mutationTypes';

export default {
  components: {
    InstructionViewPublisher,
    InstructionViewInspector,
  },
  computed: {
    isEditorOrPublisher() {
      return this.viewType === 'publisher' || this.viewType === 'editor';
    },
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    },
  },
  async created() {
    const wsMappings = {
      questionCreated: this.wsQuestionCreated,
      questionDeleted: this.wsQuestionDeleted,
      questionUpdated: this.wsQuestionUpdated,
      questionsUpdated: this.wsQuestionsUpdated,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_SUBSCRIBE_TO_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },

  beforeDestroy() {
    const wsMappings = {
      questionCreated: this.wsQuestionCreated,
      questionDeleted: this.wsQuestionDeleted,
      questionUpdated: this.wsQuestionUpdated,
      questionsUpdated: this.wsQuestionsUpdated,
    };

    Object.keys(wsMappings).forEach(async (key) => {
      await this.$store.dispatch(WEBSOCKET_EVENT_UNSUBSCRIBE_FROM_ACTION, {
        action: key,
        callback: wsMappings[key],
      });
    });
  },
  methods: {
    wsQuestionsUpdated(data) {
      this.$store.commit(MUTATION_UPDATE_QUESTIONS, data);
    },
    wsQuestionUpdated(data) {
      this.$store.commit(MUTATION_UPDATE_QUESTION, data);
    },
    wsQuestionDeleted(data) {
      this.$store.commit(REMOVE_QUESTION, data);
    },
    wsQuestionCreated(data) {
      this.$store.commit(ADD_QUESTION, data);
    },
    onSave() {
      if (this.isEditorOrPublisher) {
        this.$refs.instructionViewPublisher.forceSave();
      } else {
        this.$refs.instructionViewInspector.forceSave();
      }
    },
  },
};
</script>
