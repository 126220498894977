<template>
  <b-modal :active="true" full-screen>
    <div class="container-file-explorer">
      <div id="file-explorer" :key="key"></div>
    </div>
    <image-gallery
      v-if="isGalleryDisplayed"
      v-model="gallaryImages"
      :readOnly="true"
      @close="hideGallery"
      :startIndex="galleryStartIndex"
    />

    <footer class="modal-card-foot">
      <b-button @click="close">
        {{ $t('close') }}
      </b-button>
    </footer>
  </b-modal>
</template>
<script>
import { v4 } from 'uuid';

import client from '../../config/api';
import DIJVueConfigurations from '../../helpers/DIJVueConfigurations';
import FileExplorer from '../../../public/file-explorer';
import ImageGallery from '../layout/ImageGallery';

import {
  ADD_DOCUMENT,
  RENAME_DOCUMENT,
  DELETE_DOCUMENT,
  MOVE_DOCUMENTS,
  GET_JOB_DOCUMENTS,
  RESTORE_DOCUMENTS,
} from '../../store/documents/actions/actionTypes';

const uuidv4 = v4;
const IMAGE_EXTENSIONS = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'svg',
  'webp',
  'tiff',
];

export default {
  name: 'DocumentsModal',
  components: {
    FileExplorer,
    ImageGallery,
  },
  computed: {
    documents() {
      return [...this.$store.state.DocumentsModule.documents];
    },
  },
  props: {
    isDocumentModalVisible: {
      type: Boolean,
      default: false,
    },
    selectMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileExplorer: null,
      isGalleryDisplayed: false,
      gallaryImages: [],
      key: uuidv4(),
      galleryStartIndex: 0,
    };
  },

  mounted() {
    // Initialize js-fileexplorer when the component is mounted
    this.initializeFileExplorer();
  },

  beforeDestroy() {
    const elements = window.document.querySelectorAll(
      '.fe_fileexplorer_recycle_bin_folder'
    );

    elements.forEach((element) => element.remove());
    this.fileExplorer.Destroy();
  },

  methods: {
    close() {
      this.$emit('close');
    },

    getImageUrl(url) {
      if (!url) return null;
      if (Array.from(url)[0] !== '/') url = `/${url}`;

      const lastPart = url.split('/').pop();
      const allButLast = url.split('/').slice(0, -1).join('/');
      url = `${allButLast}/${encodeURIComponent(lastPart)}`;

      // For local env we need to point to the local s3
      if (window.location.host.includes('localhost')) {
        return `http://localhost:4566/images${url}`;
      }

      return url;
    },

    async onRefresh(folder, refresh) {
      const pathIDs = folder.GetPathIDs();
      this.$store
        .dispatch(GET_JOB_DOCUMENTS, {
          jobId: this.$route.params.id,
          pathIDs,
        })
        .then((reponse) => {
          reponse.forEach((entry) => {
            if (entry.type === 'file') {
              entry.thumb = this.getImageUrl(entry.filePath);
            }
          });

          refresh(reponse);
        });
    },

    onNewFolder(created, folder) {
      const pathIDs = folder.GetPathIDs();
      const id = uuidv4();
      const entry = {
        name: 'New Folder',
        type: 'folder',
        id,
        hash: id,
      };
      if (pathIDs.length > 1) {
        entry.folderId = pathIDs[pathIDs.length - 1];
      }
      const jobId = this.$route.params.id;
      this.$store.dispatch(ADD_DOCUMENT, { jobId, data: entry }).then(() => {
        created(entry);
      });
    },

    onRename(renamed, folder, entry, newName) {
      const newEntry = { ...entry };
      newEntry.name = newName;
      this.$store
        .dispatch(RENAME_DOCUMENT, { id: entry.id, newName })
        .then(() => {
          renamed(newEntry);
        });
    },

    async onInitUpload(startupload, fileinfo, refresh) {
      const fileName = fileinfo.file.name;
      const jobId = this.$route.params.id;
      const response = await client.post(`/job/${jobId}/presign-upload-file`, {
        fileName,
      });

      const { presignedPostData, path, uniqueFileName } = response.data;
      const dijConfigurations = new DIJVueConfigurations();
      await dijConfigurations.sendPresignedPostData(
        presignedPostData,
        fileinfo.file,
        fileName
      );

      const pathIDs = fileinfo.folder.GetPathIDs();
      const id = uuidv4();
      const entry = {
        name: fileName,
        type: 'file',
        id,
        hash: id,
        filePath: path,
        uniqueFileName,
      };

      if (pathIDs.length > 1) {
        entry.folderId = pathIDs[pathIDs.length - 1];
      }

      this.$store.dispatch(ADD_DOCUMENT, { jobId, data: entry });
      startupload(fileinfo);
      refresh();
    },

    async onOpenFile(folder, entry) {
      const jobId = this.$route.params.id;
      let path = entry.filePath;
      const fileExt = path.split('.').pop();
      if (IMAGE_EXTENSIONS.includes(fileExt.toLowerCase())) {
        const folderEntries = folder.GetEntries();
        const images = [];
        let index = 0;
        folderEntries.forEach((e) => {
          if (e.type === 'file') {
            const eFileExt = e.filePath.split('.').pop();
            if (IMAGE_EXTENSIONS.includes(eFileExt.toLowerCase())) {
              let p = e.filePath;
              if (Array.from(e.filePath)[0] !== '/') {
                p = `/${p}`;
              }
              if (entry.id === e.id) {
                this.galleryStartIndex = index;
              }
              index++;
              const lastPart = p.split('/').pop();
              const allButLast = p.split('/').slice(0, -1).join('/');
              p = `${allButLast}/${encodeURIComponent(lastPart)}`;
              images.push({
                url: p,
              });
            }
          }
        });
        this.gallaryImages = images;
        this.isGalleryDisplayed = true;
      } else {
        if (Array.from(path)[0] === '/') {
          path = path.substring(1);
        }
        const response = await client.post(
          `/job/${jobId}/reportfile-download-url`,
          { key: path }
        );

        if (this.selectMode) {
          if (fileExt !== 'pdf') return;
          this.$emit('selectFile', {
            url: response.data,
            fileName: '1',
            fileType: 'application/pdf',
          });
          this.close();
        } else {
          const anchor = document.createElement('a');
          anchor.style.display = 'none';
          anchor.href = response.data;
          anchor.target = '_blank';
          anchor.setAttribute('download', '');

          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        }
      }
    },

    // TODO: Delete also childrens of a folder
    onDelete(deleted, folder, ids) {
      if (ids.includes('recycle-bin')) {
        deleted('Can not delete recycle bin entries');
        return;
      }
      this.$store.dispatch(DELETE_DOCUMENT, ids);
      deleted(true);
    },

    onRestoreItems(items, refresh) {
      this.$store.dispatch(RESTORE_DOCUMENTS, items).then(() => {
        refresh();
      });
    },

    onMove(moved, srcpath, srcids, destfolder) {
      const pathIDs = destfolder.GetPathIDs();
      this.$store
        .dispatch(MOVE_DOCUMENTS, {
          srcids,
          destid: pathIDs[pathIDs.length - 1],
        })
        .then(() => {
          moved(true);
        });
    },

    async initializeFileExplorer() {
      const initPath = [['', 'Root (/)', { canmodify: true }]];
      await this.$store.dispatch(GET_JOB_DOCUMENTS, {
        jobId: this.$route.params.id,
        pathIDs: initPath,
      });

      const {
        onRefresh,
        onNewFolder,
        onRename,
        onInitUpload,
        onDelete,
        onMove,
        onOpenFile,
        onRestoreItems,
        selectMode,
      } = this;
      let tools = {
        new_folder: true,
        upload: true,
        download: true,
        delete: true,
        item_checkboxes: true,
      };

      if (selectMode) {
        tools = {
          new_folder: false,
          upload: false,
          download: false,
          delete: false,
          item_checkboxes: false,
        };
      }

      const options = {
        group: 'file-explorer',
        initpath: initPath,
        tools,
        concurrentuploads: 1,

        onrefresh(folder) {
          const recycleBinFolderButton = window.document.getElementsByClassName(
            'fe_fileexplorer_recycle_bin_folder'
          )[0];
          const newFolderButton = window.document.getElementsByClassName(
            'fe_fileexplorer_folder_tool_new_folder'
          )[0];
          const uplodButton = window.document.getElementsByClassName(
            'fe_fileexplorer_folder_tool_upload'
          )[0];
          const clipBoardButton = window.document.getElementsByClassName(
            'fe_fileexplorer_folder_tool_paste'
          )[0];
          const cutButon = window.document.getElementsByClassName(
            'fe_fileexplorer_folder_tool_cut'
          )[0];
          const deleteButton = window.document.getElementsByClassName(
            'fe_fileexplorer_folder_tool_delete'
          )[0];

          if (
            folder.GetPathIDs()[1] === 'recycle-bin' ||
            folder.GetPathIDs()[1] === 'image-gallery' ||
            selectMode
          ) {
            newFolderButton.classList.add('display-none');
            uplodButton.classList.add('display-none');
            clipBoardButton.classList.add('display-none');
            cutButon.classList.add('display-none');
            deleteButton.classList.add('display-none');
            if (selectMode) {
              recycleBinFolderButton.classList.add('display-none');
            }
          } else {
            newFolderButton.classList.remove('display-none');
            uplodButton.classList.remove('display-none');
            clipBoardButton.classList.remove('display-none');
            cutButon.classList.remove('display-none');
            deleteButton.classList.remove('display-none');
            recycleBinFolderButton.classList.remove('display-none');
          }

          onRefresh(folder, (entries) => {
            // To forece update clear and reset the current folder
            folder.SetEntries([]);
            folder.UpdateEntries(entries);
          });
        },

        onrename(renamed, folder, entry, newname) {
          onRename(renamed, folder, entry, newname);
          this.RefreshFolders(true);
        },
        oninitupload(startupload, fileinfo) {
          onInitUpload(startupload, fileinfo, () => {
            this.RefreshFolders(true);
          });
        },
        onopenfile: onOpenFile,
        onmove(moved, srcpath, srcids, destfolder) {
          onMove(moved, srcpath, srcids, destfolder);
        },
        onnewfolder: onNewFolder,
        ondelete: onDelete,
      };

      const FileExplorerTool_Recycle_Bin = function (fe) {
        if (!(this instanceof FileExplorerTool_Recycle_Bin))
          return new FileExplorerTool_Recycle_Bin(fe);
        // Register a toolbar button with File Explorer.
        const node = fe.AddToolbarButton(
          'fe_fileexplorer_recycle_bin_folder',
          'Recycle Bin'
        );

        // Handle clicks.
        const ClickHandler = function () {
          const path = [
            ['', 'Root (/)'],
            ['recycle-bin', 'Recycle Bin', { canmodify: false }],
          ];

          fe.SetPath(path);
        };
        node.addEventListener('click', ClickHandler);
        const UpdateToolHandler = function () {
          node.classList.remove('fe_fileexplorer_disabled');

          fe.ToolStateUpdated();
        };

        fe.ToolStateUpdated();

        fe.addEventListener('update_tool', UpdateToolHandler);

        // Cleanly handle the destroy event.
        const DestroyToolHandler = function () {
          node.removeEventListener('click', ClickHandler);
        };
        fe.addEventListener('destroy', DestroyToolHandler);
      };

      const FileExplorerTool_Image_Gallery = function (fe) {
        if (!(this instanceof FileExplorerTool_Image_Gallery))
          return new FileExplorerTool_Image_Gallery(fe);
        // Register a toolbar button with File Explorer.
        const node = fe.AddToolbarButton(
          'fe_fileexplorer_image_gallery_folder',
          'Image Gallery'
        );

        // Handle clicks.
        const ClickHandler = function () {
          const path = [
            ['', 'Root (/)'],
            ['image-gallery', 'Image Gallery', { canmodify: false }],
          ];

          fe.SetPath(path);
        };
        node.addEventListener('click', ClickHandler);
        const UpdateToolHandler = function () {
          node.classList.remove('fe_fileexplorer_disabled');

          fe.ToolStateUpdated();
        };

        fe.ToolStateUpdated();

        fe.addEventListener('update_tool', UpdateToolHandler);

        // Cleanly handle the destroy event.
        const DestroyToolHandler = function () {
          node.removeEventListener('click', ClickHandler);
        };
        fe.addEventListener('destroy', DestroyToolHandler);
      };

      const FileExplorerTool_Recycle_Restore = function (fe) {
        if (!(this instanceof FileExplorerTool_Recycle_Restore))
          return new FileExplorerTool_Recycle_Restore(fe);
        // Register a toolbar button with File Explorer.
        const node = fe.AddToolbarButton(
          'fe_fileexplorer_recycle_bin_restore',
          'Restore file'
        );

        const ClickHandler = function () {
          const items = fe.GetSelectedItemIDs();
          if (items.length === 0) {
            return;
          }
          onRestoreItems(items, () => {
            fe.RefreshFolders(true);
          });
        };

        node.addEventListener('click', ClickHandler);
        const UpdateToolHandler = function () {
          const folder = fe.GetCurrentFolder();

          if (folder.GetPathIDs()[1] === 'recycle-bin') {
            node.classList.remove('display-none');

            if (fe.GetSelectedItemIDs().length > 0) {
              node.classList.remove('display-none');
              node.classList.remove('fe_fileexplorer_disabled');
            } else {
              node.classList.add('fe_fileexplorer_disabled');
            }
          } else {
            node.classList.add('display-none');
            node.classList.add('fe_fileexplorer_disabled');
          }

          fe.ToolStateUpdated();
        };

        fe.addEventListener('update_tool', UpdateToolHandler);
        // Cleanly handle the destroy event.
        const DestroyToolHandler = function () {
          node.removeEventListener('click', ClickHandler);
        };
        fe.addEventListener('destroy', DestroyToolHandler);
      };

      if (!window.toolsAdded) {
        window.FileExplorer.RegisterTool(1, FileExplorerTool_Recycle_Bin);
        window.FileExplorer.RegisterTool(1, FileExplorerTool_Recycle_Restore);
        window.FileExplorer.RegisterTool(1, FileExplorerTool_Image_Gallery);
        window.toolsAdded = true;
      }

      const elem = document.getElementById('file-explorer');
      // eslint-disable-next-line no-new
      this.fileExplorer = new window.FileExplorer(elem, options);
    },

    hideGallery() {
      this.isGalleryDisplayed = false;
    },
  },
};
</script>
<style scoped lang="scss">
.modal-card-foot {
  justify-content: flex-end;
}
/* Import the CSS for js-fileexplorer */
@import '../../../public/file-explorer.css';

.container-file-explorer {
  height: calc(100% - 77px);
}
#file-explorer {
  height: 100%;
}
</style>

<style>
[data-feid='recycle-bin'] {
  display: none;
}

.fe_fileexplorer_item_checkbox {
  width: 20px;
  height: 20px;
}

.fe_fileexplorer_folder_tool_download {
  display: none;
}

.fe_fileexplorer_image_gallery_folder {
  background-image: url('../../../public/img/gallery.png') !important;
  background-position: center;
  background-size: 34px 34px;
  image-rendering: auto;
  background-repeat: no-repeat;
}

.fe_fileexplorer_recycle_bin_folder {
  background-image: url('../../../public/img/recycle-bin.png') !important;
  background-position: center;
  background-size: 34px 34px;
  image-rendering: auto;
  background-repeat: no-repeat;
}

.fe_fileexplorer_recycle_bin_restore {
  background-image: url('../../../public/img/restore-bin.png') !important;
  background-position: center;
  background-size: 34px 34px;
  image-rendering: auto;
  background-repeat: no-repeat;
}
/* For all iPads in both portrait and landscape */
@media only screen and (min-width: 768px) and (max-width: 1366px) {
  .fe_fileexplorer_navtool_back {
    height: 40px !important;
    background-position: -0px -0px !important;
    background-size: 190px;
  }

  .fe_fileexplorer_navtool_forward {
    height: 40px !important;
    background-position: -100px -0px !important;
    background-size: 190px;
  }

  .fe_fileexplorer_navtool_up {
    height: 40px !important;
    width: 34px !important;
    background-position: -0px -35px !important;
    background-size: 190px;
  }

  .fe_fileexplorer_navtool_history {
    visibility: hidden;
  }

  .fe_fileexplorer_wrap .fe_fileexplorer_navtools button {
    image-rendering: auto !important;
  }
}

.fe_fileexplorer_wrap .fe_fileexplorer_item_icon {
  height: 100px !important;
}

.fe_fileexplorer_wrap .fe_fileexplorer_item_wrap {
  width: 7em !important;
}

.fe_fileexplorer_wrap .fe_fileexplorer_item_icon_file {
  background-position: 9px -96px !important;
  background-size: 260px !important;
}

.fe_fileexplorer_wrap .fe_fileexplorer_item_icon {
  width: 100px !important;
}

.fe_fileexplorer_wrap .fe_fileexplorer_item_icon_folder {
  background-position: -72px -69px !important;
  background-size: 220px;
  image-rendering: auto;
}

.display-none {
  display: none;
}
</style>
